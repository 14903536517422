import React from "react";
import Footer from "../../components/footer";
import NavBar from "../../components/navbar";
import Helmet from "react-helmet";
import './Careers.css';



function Careers() {
  return (
    <div>
      <Helmet>
        <title>TekStrive - IT consulting and Staffing</title>
      </Helmet>
      <NavBar />
      <div>
        <Hero />
        <Openings/>
        <Footer />
      </div>
    </div>
  );
}

export default Careers;

const Hero = () => {
  return (
      <>
          <section className='section-global bg-shade-blue hero'>
              <div className="container">
                  <div className="row">
                      <div className="col-lg-7">
                          <div className="section-tag mb-8" data-aos="fade-up">Contact</div>
                          <h1 className='display-1' data-aos="fade-up">We are always here to ensure customer satisfaction</h1>
                      </div>
                  </div>
              </div>
          </section>
      </>
  )
}

function Openings() {
  return (
    <>
      <div>
        <h2 className="p-4">Job Openings</h2>
      </div>
      <div className="openings">
        <div className="job-box">
          <div className="my-auto">
          <h4>Data Scientist</h4>
          <p style={{color:'red'}}>Full-time</p>
          <p>Aliquam sodales, platea elementum dictumst. Porta habitasse amet, purus erat senectus sodales bibendum.</p>
          <a className="btn btn-primary btn-lg">Apply now</a>
          </div>
        </div>
        <div className="job-box">
          <div className="my-auto">
          <h4>Data Scientist</h4>
          <p style={{color:'red'}}>Full-time</p>
          <p>Aliquam sodales, platea elementum dictumst. Porta habitasse amet, purus erat senectus sodales bibendum.</p>
          <a className="btn btn-primary btn-lg">Apply now</a>
          </div>
        </div>
        <div className="job-box">
          <div className="my-auto">
          <h4>Data Scientist</h4>
          <p style={{color:'red'}}>Full-time</p>
          <p>Aliquam sodales, platea elementum dictumst. Porta habitasse amet, purus erat senectus sodales bibendum.</p>
          <a className="btn btn-primary btn-lg">Apply now</a>
          </div>
        </div>
        <div className="job-box">
          <div className="my-auto">
          <h4>Data Scientist</h4>
          <p style={{color:'red'}}>Full-time</p>
          <p>Aliquam sodales, platea elementum dictumst. Porta habitasse amet, purus erat senectus sodales bibendum.</p>
          <a className="btn btn-primary btn-lg">Apply now</a>
          </div>
        </div>
        <div className="job-box">
          <div className="my-auto">
          <h4>Data Scientist</h4>
          <p style={{color:'red'}}>Full-time</p>
          <p>Aliquam sodales, platea elementum dictumst. Porta habitasse amet, purus erat senectus sodales bibendum.</p>
          <a className="btn btn-primary btn-lg">Apply now</a>
          </div>
        </div>
        <div className="job-box">
          <div className="my-auto">
          <h4>Data Scientist</h4>
          <p style={{color:'red'}}>Full-time</p>
          <p>Aliquam sodales, platea elementum dictumst. Porta habitasse amet, purus erat senectus sodales bibendum.</p>
          <a className="btn btn-primary btn-lg">Apply now</a>
          </div>
        </div>
      </div>
    </>
  );
}
