import Helmet from "react-helmet"
import { useEffect } from "react"
import ContactSection from "../../components/contactSection"
import FeatureCards from '../../components/featureCards'
import Footer from "../../components/footer"
import NavBar from "../../components/navbar"
import ServiceCards from '../../components/serviceCards'
import './style.scss'
import AOS from "aos";
import "aos/dist/aos.css";
import { motion } from "framer-motion"


const Services = () => {

    useEffect(() => {
        AOS.init({
            delay: 0,
            duration: 1000,
            easing: 'ease',
        });
        AOS.refresh();
      }, []);

    return(
        <motion.div
        initial={{opacity: 0}}
        animate={{opacity:1}}
        exit={{opacity:0}}
        transition ={{duration:1.5}}>
            <Helmet>
                <title>TechStrive - Services</title>
            </Helmet>
            <NavBar/>
            <div className="services-container">
                <Hero/>
                <ServicesSection/>
                <Features/>
                <Contact/>
                <Footer/>
            </div>
        </motion.div>
    )
}

const Hero = () => {
    return(
        <>  
            <section className='section-global bg-shade-blue hero'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="section-tag mb-8" data-aos="fade-up">Services</div>
                            <h1 className='display-1'>We help transform your business from top to bottom.</h1>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const ServicesSection = () => {

    const services = [
        {
            name: 'Staffing Solution',
            info: 'We believe right person for right job, we believe and execute this to meet business desires.',
            icoUrl: 'assets/service_ico1.svg'
        },
        {
            name: 'Cyber Security',
            info: 'We proudly announce we are innovaters in the Information Security industry backed by all out and out support.',
            icoUrl: 'assets/service_ico2.svg'
        },
        {
            name: 'Infrastructure Service',
            info: 'Cloud Services, Network Services, Collaboration Services and Technology Deployment Services.',
            icoUrl: 'assets/service_ico3.svg'
        },
        {
            name: 'Application Design',
            info: 'Application/Mobile/Hybrid Development/Outsourcing/services, we are experts in Python, Java, NodeJS, Android, iOS, Windows and etc.',
            icoUrl: 'assets/service_ico4.svg'
        },
        {
            name: 'ERP Support Service',
            info: 'Operations to traditional ERP or assimilating Cloud ERP, logistical operations and supply chain, HR Management and etc.',
            icoUrl: 'assets/service_ico5.svg'
        },
        {
            name: 'Automation',
            info: 'We adopted the AI, IoT, Deep Learning, Machines Learning and etc.',
            icoUrl: 'assets/service_ico6.svg'
        },
        {
            name: 'Data Engineering',
            info: 'We also expertise in the data engineering, data anaylsis, tera data, peta data and etc.',
            icoUrl: 'assets/service_ico7.svg'
        },
        {
            name: 'Quantum / Blockchain ',
            info: 'Our simple process helps us stay nimble, evolve rapidly and ultimately grow brands.',
            icoUrl: 'assets/service_ico8.svg'
        }
    ]

    return(
        <>
            <section className='section-global'>
                <div className="container" data-aos="fade-up">
                    <div className="row gx-md-5 gy-5">
                        {
                            services.map((service,i) => 
                                <div className="col-lg-4 col-xl-3 col-md-6 col-sm-6"  key={i}>
                                    <ServiceCards data={service} src="services"/>
                                </div>                    
                            )
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

const Features = () => {

    const featurs_data = [
        {
            title: 'Research',
            text: 'Our simple process helps us stay nimble, evolve rapidly and ultimately grow brands.'
        },
        {
            title: 'Build',
            text: 'Our simple process helps us stay nimble, evolve rapidly and ultimately grow brands.'
        },
        {
            title: 'Scale',
            text: 'Our simple process helps us stay nimble, evolve rapidly and ultimately grow brands.'
        }
    ]

    return(
        <>
            <section className='section-global bg-shade-blue'>
                <div className="container" data-aos="fade-up">
                    <div className="row mb-40">
                        <div className="col-xl-6 col-lg-8 offset-xl-3 offset-lg-2 text-center">
                            <div className="section-tag mb-8">How we work</div>
                            <h2>Extremely smooth workflow with cosistancy</h2>
                        </div>
                    </div>
                </div>
                <div className="container container-2" data-aos="fade-up">
                    <div className="row gy-4 gx-0 gx-md-5">
                        <FeatureCards data={featurs_data} src='service'/>
                    </div>
                </div>
            </section>
        </>
    )
}

const Contact = () => {

    const contact_data = {
            title: 'Have an idea?',
            title_highlight: "Let's talk",
            text: "One of the best industry service providers with top tier talented individuals.",
            link: '/contact'
        }

    return(
        <>
            <ContactSection contact_data={contact_data}/>
        </>
    )
}

export default Services