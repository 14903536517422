import './style.scss'
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from 'react';


const BrandSection = ({brands,src, light, bordered, bg, pt, customTitle}) => {

    useEffect(() => {
        AOS.init({
            delay: 0,
            duration: 1500,
            easing: 'ease',
        });
        AOS.refresh();
      }, []);

    return(
        <section className={(light?'':'dark')+(bg?` ${bg}`:'')+" brand-section-container"}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className={"brand-section "+(bordered?'bordered':'')+(pt?'pt-60':'')}>
                            <div className="txt-3 fw-500 text-center mb-20" data-aos="fade-up">{customTitle?customTitle:'Trusted by'}</div>
                            <div className="brands-container">
                                {
                                    brands.map((brand, index) => 
                                        <div className="brand" key={index} >
                                            <img src={require(`../../pages/${src}/${brand.imgUrl}`)} alt={brand.name} className="img-fluid" data-aos="flip-up"/>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BrandSection